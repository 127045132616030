.image-rotator {
    width: 500px; /* Adjust the container width as needed */
    height: 300px; /* Adjust the container height as needed */
    overflow: hidden;
  }
  
  .image-rotator img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    transition: transform 0.5s ease-in-out; /* Add a smooth transition effect */
  }
  