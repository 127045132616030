/* Footer.css */

.footer {
    background-color: #585757;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Add more styles as needed */
  