/* Hide the sublist initially */
.sublist {
    display: none;
    margin: 0; /* Remove all margins */
    padding: 0; /* Remove all padding */
    list-style: none; /* Remove bullet points */  
    position: absolute; /* Position it absolutely within the parent */
    top: 100%; /* Position it right below the parent */
    left: 0; /* Align it to the left of the parent */
  }
  
  /* Display the sublist when "About Us" is hovered */
  .menu-list:hover .sublist {
    display: block;
  }

  .wide-list {
    width: 200px;
  }